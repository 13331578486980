


























































import { Component } from "vue-property-decorator";
import FscPageHeader from "@/components/FscPageHeader.vue";
import FscSimpleCard from "@/components/Card/FscSimpleCard.vue";
import ReportFinances from "@/views/Report/ReportFinances.vue";
import ReportEducations from "@/views/Report/ReportEducations.vue";
import { IBasicNamedDTO } from "@/interfaces/IBasicNamedDTO";
import ReportFinancesFilter from "@/views/Report/ReportFinancesFilter.vue";
import { ICrudOptions } from "@/interfaces/ICrudOptions";
import { namespace } from "vuex-class";
import { IBranch } from "@/interfaces/IBranch";
import { IInstructor } from "@/interfaces/IInstructor";
import ReportFinancesChart from "@/views/Report/ReportFinancesChart.vue";
import ReportFinanceFilter from "@/views/Report/ReportFinanceFilter.vue";
import DefaultInstructorSorting from "@/constants/DefaultInstructorSorting";
import { mixins } from "vue-class-component";
import ReportRequestMixin from "@/mixins/Request/ReportRequestMixin";
import ReportEducationFilter from "@/views/Report/ReportEducationFilter.vue";
import { IReportItem } from "@/interfaces/IReportItem";

const LicenseClassModule = namespace("license-class");
const BranchModule = namespace("branch");
const InstructorModule = namespace("instructor");
const PaymentWorkFlowModule = namespace("payment-workflow");
const FinanceModule = namespace("finance");

@Component({
  components: {
    ReportEducationFilter,
    ReportFinancesChart,
    ReportFinancesFilter,
    ReportFinances,
    FscSimpleCard,
    FscPageHeader,
    ReportFinanceFilter,
    ReportEducations,
  },
})
export default class Finance extends mixins(ReportRequestMixin) {
  public name = "Finance";

  @LicenseClassModule.Action("groupsList/findAll")
  public findAllLicenseClassGroups!: (options: ICrudOptions) => Promise<void>;

  @LicenseClassModule.Getter("groupsList/getDataList")
  public licenseClassGroups!: Array<IBasicNamedDTO>;

  @BranchModule.Action("findAll")
  public findAllBranches!: (options: ICrudOptions) => Promise<void>;

  @BranchModule.Getter("getDataList")
  public branches!: Array<IBranch>;

  @InstructorModule.Action("filter")
  public filterInstructors!: (options: ICrudOptions) => Promise<void>;

  @InstructorModule.Getter("getDataList")
  public instructors!: Array<IInstructor>;

  @PaymentWorkFlowModule.Action("findAll")
  public findAllPaymentWorkflows: any;

  @PaymentWorkFlowModule.Getter("getDataList")
  public allPaymentWorkflows: any;

  @FinanceModule.Action("findReportPayments")
  public findReportPayments: any;

  @FinanceModule.Getter("getDataList")
  public paymentsReport: any;

  @FinanceModule.Getter("getIsLoading")
  public paymentsReportLoading: any;

  @FinanceModule.Action("findReportBalance")
  public findReportBalance: any;

  @FinanceModule.Getter("getDataList")
  public balanceReport: any;

  @FinanceModule.Getter("getIsLoading")
  public balanceReportLoading: any;

  public selectedRow: null | IBasicNamedDTO = null;

  public filterData: any = null;

  public filterInfo: any = null;

  public paymentsReportData: any = null;

  public balanceReportData: any = null;

  public onSelectedRow(row: IBasicNamedDTO): void {
    this.selectedRow = row;
    this.paymentsReportData = null;
    this.balanceReportData = null;
    this.filterData = null;
    this.filterInfo = null;
  }

  private get reportFinances(): Array<IReportItem> {
    return [
      {
        id: 1,
        name: this.$t("automatic_payment.payments_made").toString(),
      },
      {
        id: 2,
        name: this.$t("report.debts").toString(),
      },
    ];
  }

  public mounted(): void {
    this.findAllLicenseClassGroups({
      resource: "/license-class-groups",
    });

    this.findAllBranches({
      resource: "/branches",
      params: { archived: false },
    });

    this.filterInstructors({ resource: "instructor", filter: { archived: false, ...DefaultInstructorSorting } });

    this.findAllPaymentWorkflows({
      resource: "payment-workflows",
    });

    this.fetchDailyDataStartPeriod();
  }

  public async onSubmitFilterForm(filter: any, filterInfo: any): Promise<void> {
    this.filterData = filter;
    this.filterInfo = filterInfo;
    if (this.selectedRow && this.selectedRow.id === 1) {
      this.balanceReportData = null;
      await this.findReportPayments(filter);
      this.paymentsReportData = this.paymentsReport ? this.paymentsReport : null;
    } else if (this.selectedRow && this.selectedRow.id === 2) {
      this.paymentsReportData = null;
      await this.findReportBalance(filter);
      this.balanceReportData = this.balanceReport ? this.balanceReport : null;
    }
  }

  public onCloseFilterForm(): void {
    this.selectedRow = null;
    this.paymentsReportData = null;
  }
}
