


























































import { Component, Prop, Watch } from "vue-property-decorator";
import FscSimpleCard from "@/components/Card/FscSimpleCard.vue";
import Datepicker from "@/components/Datepicker.vue";
import AbortButton from "@/components/Button/AbortButton.vue";
import SaveButton from "@/components/Button/SaveButton.vue";
import { IBasicNamedDTO } from "@/interfaces/IBasicNamedDTO";
import { PropType } from "vue";
import { IBranch } from "@/interfaces/IBranch";
import BaseMixin from "@/mixins/BaseMixin";
import { mixins } from "vue-class-component";
import Validation from "@/components/Validation.vue";
import { Validations } from "vuelidate-property-decorators";
import { required } from "vuelidate/lib/validators";
import moment from "moment";

@Component({
  components: {
    Validation,
    SaveButton,
    AbortButton,
    Datepicker,
    FscSimpleCard,
  },
})
export default class ReportFinancesFilter extends mixins(BaseMixin) {
  public name = "ReportFinancesFilter";

  @Prop({ type: String })
  public dailyDataStartPeriod!: string;

  @Prop({ type: Boolean, default: () => false })
  public dailyDataStartPeriodLoading!: boolean;

  @Prop({ type: Array as PropType<Array<IBasicNamedDTO>>, default: () => [] })
  public licenseClassGroups!: Array<IBasicNamedDTO>;

  @Prop({ type: Array as PropType<Array<IBranch>>, default: () => [] })
  public branches!: Array<IBranch>;

  @Prop({ default: () => [] })
  public paymentWorkFlows!: any;

  @Prop()
  public selectedRow!: any;

  public startDate: string | null = null;
  public endDate = null;
  public licenseClassGroupIds: any = [];
  public branchIds: Array<number> | null = [];
  public paymentWorkflow: any = null;

  private onAbort(): void {
    this.$emit("on-close");
  }

  public branchLabel(branch: IBranch): string {
    return `${branch.postalCode} - ${branch.location}`;
  }

  public onSubmit(): void {
    this.$v.$touch();
    if (this.$v.$invalid) return;
    const filter = {
      startDate: this.startDate,
      endDate: this.endDate,
      licenseClassGroupIds: this.onLicenseClassGroupIds(),
      branchIds: this.onBranchIds(),
      paymentWorkflow: this.paymentWorkflow ? this.paymentWorkflow.id : null,
    };
    const filterInfo = {
      startDate: this.startDate,
      endDate: this.endDate,
      licenseClassGroupIds: this.licenseClassGroupIds,
      branchIds: this.branchIds,
      paymentWorkflow: this.paymentWorkflow ? this.paymentWorkflow : null,
    };
    this.$emit("on-submit", filter, filterInfo);
    this.$v.$reset();
  }

  public onLicenseClassGroupIds() {
    if (this.licenseClassGroupIds) {
      let ids = this.licenseClassGroupIds.map((licenseClass: any) => {
        return licenseClass.id;
      });
      return ids;
    }
    return [];
  }

  public onBranchIds() {
    if (this.branchIds) {
      let ids = this.branchIds.map((branch: any) => {
        return branch.id;
      });
      return ids;
    }
    return [];
  }

  @Validations()
  public validations(): Record<string, any> {
    return {
      startDate: { required },
      endDate: { required },
    };
  }

  @Watch("dailyDataStartPeriod", { immediate: true })
  private onChangeDailyDataStartPeriod(dailyDataStartPeriod: string) {
    if (dailyDataStartPeriod && this.isDebts) {
      this.startDate = moment(dailyDataStartPeriod, "DD.MM.YYYY").format("YYYY-MM-DD");
    }
  }

  private get isDebts() {
    return this.selectedRow.id === 2;
  }

  private get dailyDataStartPeriodComputed() {
    if (this.isDebts) {
      return this.dailyDataStartPeriod;
    }
    return undefined;
  }
}
