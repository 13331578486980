














import { Vue, Component, Prop } from "vue-property-decorator";
import { PropType } from "vue";
import { IReportItem } from "@/interfaces/IReportItem";

@Component
export default class ReportEducations extends Vue {
  public name = "ReportEducations";

  @Prop({ type: Array as PropType<Array<IReportItem>>, required: true })
  public items!: Array<IReportItem>;

  public selectedRow: null | IReportItem = null;

  public onRowClicked(row: any): void {
    this.selectedRow = row;
    this.$emit("selected-row", row);
  }

  public isActive(item: IReportItem): boolean {
    return Boolean(this.selectedRow && this.selectedRow.id === item.id);
  }

  private get itemsComputed() {
    return this.items.filter((item: IReportItem) => item.visible === undefined || item.visible === true);
  }
}
