













































































import { Component, Prop, Watch } from "vue-property-decorator";
import FscSimpleCard from "@/components/Card/FscSimpleCard.vue";
import Datepicker from "@/components/Datepicker.vue";
import AbortButton from "@/components/Button/AbortButton.vue";
import SaveButton from "@/components/Button/SaveButton.vue";
import { IBasicNamedDTO } from "@/interfaces/IBasicNamedDTO";
import { PropType } from "vue";
import { IBranch } from "@/interfaces/IBranch";
import { IInstructor } from "@/interfaces/IInstructor";
import BaseMixin from "@/mixins/BaseMixin";
import { mixins } from "vue-class-component";
import Validation from "@/components/Validation.vue";
import { Validations } from "vuelidate-property-decorators";
import { required } from "vuelidate/lib/validators";
import { namespace } from "vuex-class";
import moment from "moment/moment";

const EducationEventModule = namespace("education-event");

@Component({
  components: {
    Validation,
    SaveButton,
    AbortButton,
    Datepicker,
    FscSimpleCard,
  },
})
export default class ReportEducationFilter extends mixins(BaseMixin) {
  public name = "ReportEducationFilter";
  @Prop({ type: Boolean, default: () => false })
  public showHintText!: boolean;

  @Prop({ type: String })
  public dailyDataStartPeriod!: string;

  @Prop({ type: Boolean, default: () => false })
  public dailyDataStartPeriodLoading!: boolean;

  @Prop({ type: Array as PropType<Array<IBasicNamedDTO>>, default: () => [] })
  public licenseClassGroups!: Array<IBasicNamedDTO>;

  @Prop({ type: Array as PropType<Array<IBranch>>, default: () => [] })
  public branches!: Array<IBranch>;

  @Prop({ type: Array as PropType<Array<IInstructor>>, default: () => [] })
  public instructors!: Array<IInstructor>;

  @Prop({ type: Array as PropType<Array<IBasicNamedDTO>>, default: () => [] })
  public paymentWorkFlows!: Array<IBasicNamedDTO>;

  @Prop({ type: Boolean, default: () => false })
  public showPaymentWorkflows!: boolean;

  @Prop({ type: Array, default: () => [] })
  public licenseClasses!: any;

  @Prop()
  public selectedRow!: any;

  @EducationEventModule.Action("findAll")
  public findAllMultiselectClasses: any;

  @EducationEventModule.Getter("getDataList")
  public allMultiselectClasses: any;

  public licenseClassMultiselect = false;
  public licenseClassGroupIds: any = null;
  public branchIds: any = null;
  public instructorIds: any = null;
  public startDate: string | null = null;
  public endDate = null;
  public paymentWorkflow: any = null;
  public licenseClassIds: any = null;
  public licenseClassesOptions: any = [];
  public licenseClassesMultiselectIds: any = null;

  private onSubmit(): void {
    this.$v.$touch();
    if (this.$v.$invalid) return;

    const filterInfo = {
      startDate: this.startDate,
      endDate: this.endDate,
      licenseClassGroupIds: this.licenseClassGroupIds,
      branchIds: this.branchIds,
      instructorIds: this.instructorIds,
      paymentWorkflow: this.paymentWorkflow ? this.paymentWorkflow : null,
      licenseClassIds: this.licenseClassIds,
      licenseClasses: this.licenseClassesMultiselectIds,
    };

    this.$emit(
      "on-submit",
      {
        licenseClassGroupIds: this.onLicenseClassGroupIds(),
        branchIds: this.onBranchIds(),
        instructorIds: this.onInstructorIds(),
        startDate: this.startDate,
        endDate: this.endDate,
        paymentWorkflow: this.paymentWorkflow,
        licenseClassIds: this.licenseClassIds,
        licenseClasses: this.onLicenseClassesMultiselectIds(),
      },
      filterInfo
    );
  }

  private onAbort(): void {
    this.$emit("on-close");
  }

  public branchLabel(branch: IBranch): string {
    return `${branch.postalCode} - ${branch.location}`;
  }

  @Validations()
  public validations(): Record<string, any> {
    return {
      startDate: {
        required,
      },
      endDate: {
        required,
      },
      licenseClassGroupIds: {
        //required,
      },
    };
  }

  public onLicenseClassGroupIds() {
    if (this.licenseClassGroupIds) {
      let ids = this.licenseClassGroupIds.map((licenseClass: any) => {
        return licenseClass.id;
      });
      return ids;
    }
    return null;
  }

  public onLicenseClassesMultiselectIds() {
    if (this.licenseClassesMultiselectIds) {
      let ids = this.licenseClassesMultiselectIds.map((licenseClass: any) => {
        return licenseClass.id;
      });
      return ids;
    }
    return null;
  }

  public onBranchIds() {
    if (this.branchIds) {
      let ids = this.branchIds.map((branch: any) => {
        return branch.id;
      });
      return ids;
    }
    return null;
  }

  public onInstructorIds() {
    if (this.instructorIds) {
      let ids = this.instructorIds.map((instructor: any) => {
        return instructor.id;
      });
      return ids;
    }
    return null;
  }

  @Watch("dailyDataStartPeriod", { immediate: true })
  private onChangeDailyDataStartPeriod(dailyDataStartPeriod: string) {
    if (dailyDataStartPeriod && this.showHintText) {
      this.startDate = moment(dailyDataStartPeriod, "DD.MM.YYYY").format("YYYY-MM-DD");
    }
  }

  @Watch("selectedRow", { immediate: true, deep: true })
  public async onSelectedRowChange(row: any): Promise<void> {
    if (!row) return;
    if (row && row.id === 5) {
      this.licenseClassMultiselect = true;
    } else {
      this.licenseClassMultiselect = false;
    }
    switch (row.id) {
      case 1:
        await this.findAllMultiselectClasses({
          resource: `license-classes/for-export?exportType=${row.id}`,
        });
        this.licenseClassesOptions = this.allMultiselectClasses;
        break;
      case 5:
        await this.findAllMultiselectClasses({
          resource: `license-classes/for-export?exportType=8`,
        }).catch((e: any) => console.log("error", e));
        this.licenseClassesOptions = this.allMultiselectClasses;
        break;
      case 6:
        await this.findAllMultiselectClasses({
          resource: `license-classes/for-export?exportType=${row.id}`,
        });
        this.licenseClassesOptions = this.allMultiselectClasses;
        break;
      case 7:
        await this.findAllMultiselectClasses({
          resource: `license-classes/for-export?exportType=6`,
        });
        this.licenseClassesOptions = this.allMultiselectClasses;
        break;
      case 8:
        await this.findAllMultiselectClasses({
          resource: `license-classes/for-export?exportType=4`,
        });
        this.licenseClassesOptions = this.allMultiselectClasses;
        break;
      case 9:
        await this.findAllMultiselectClasses({
          resource: `license-classes/for-export?exportType=7`,
        });
        this.licenseClassesOptions = this.allMultiselectClasses;
        break;
      case 10:
        await this.findAllMultiselectClasses({
          resource: `license-classes/for-export?exportType=2`,
        });
        this.licenseClassesOptions = this.allMultiselectClasses;
        break;
      case 11:
        await this.findAllMultiselectClasses({
          resource: `license-classes/for-export?exportType=8`,
        });
        this.licenseClassesOptions = this.allMultiselectClasses;
        break;
      default:
        console.log("DEFAULT");
    }
  }

  public get selectedRowIdType() {
    if (!this.selectedRow && !this.selectedRow.id) return false;
    switch (this.selectedRow.id) {
      case 1:
        return true;
      case 5:
        return true;
      case 6:
        return true;
      case 7:
        return true;
      case 8:
        return true;
      case 9:
        return true;
      case 10:
        return true;
      case 11:
        return true;
      default:
        return false;
    }
  }

  public get instructorField() {
    if (!this.selectedRow?.id) return true;
    switch (this.selectedRow.id) {
      case 1:
        return false;
      case 5:
        return false;
      case 8:
        return false;
      case 9:
        return false;
      case 11:
        return false;
      default:
        return true;
    }
  }

  private get dailyDataStartPeriodComputed() {
    const id = this.selectedRow?.id;
    if (id === 11 || id === 5) {
      return this.dailyDataStartPeriod;
    }
    return undefined;
  }
}
