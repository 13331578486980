


































































import { Component, Prop } from "vue-property-decorator";
import FscSimpleCard from "@/components/Card/FscSimpleCard.vue";
import Datepicker from "@/components/Datepicker.vue";
import AbortButton from "@/components/Button/AbortButton.vue";
import SaveButton from "@/components/Button/SaveButton.vue";
import { IBasicNamedDTO } from "@/interfaces/IBasicNamedDTO";
import { PropType } from "vue";
import { IBranch } from "@/interfaces/IBranch";
import { IInstructor } from "@/interfaces/IInstructor";
import BaseMixin from "@/mixins/BaseMixin";
import { mixins } from "vue-class-component";
import Validation from "@/components/Validation.vue";
import { Validations } from "vuelidate-property-decorators";
import { required } from "vuelidate/lib/validators";

@Component({
  components: { Validation, SaveButton, AbortButton, Datepicker, FscSimpleCard },
})
export default class ReportFinanceFilter extends mixins(BaseMixin) {
  public name = "ReportFinanceFilter";

  @Prop({ type: Array as PropType<Array<IBasicNamedDTO>>, default: () => [] })
  public licenseClassGroups!: Array<IBasicNamedDTO>;

  @Prop({ type: Array as PropType<Array<IBranch>>, default: () => [] })
  public branches!: Array<IBranch>;

  @Prop({ type: Array as PropType<Array<IInstructor>>, default: () => [] })
  public instructors!: Array<IInstructor>;

  @Prop({ type: Array as PropType<Array<IBasicNamedDTO>>, default: () => [] })
  public paymentWorkflows!: Array<IBasicNamedDTO>;

  public licenseClassGroupIds: Array<number> | null = null;
  public branchIds: Array<number> | null = null;
  public instructorIds: Array<number> | null = null;
  public startDate = null;
  public endDate = null;
  public paymentWorkflow = null;

  private onSubmit(): void {
    this.$v.$touch();
    if (this.$v.$invalid) return;

    this.$emit("on-submit", {
      licenseClassGroupIds: this.licenseClassGroupIds,
      branchIds: this.branchIds,
      instructorIds: this.instructorIds,
      startDate: this.startDate,
      endDate: this.endDate,
      paymentWorkflow: this.paymentWorkflow,
    });
  }

  private onAbort(): void {
    this.$emit("on-close");
  }

  public branchLabel(branch: IBranch): string {
    return `${branch.postalCode} - ${branch.location}`;
  }

  @Validations()
  public validations(): Record<string, any> {
    return {
      startDate: {
        required,
      },
      endDate: {
        required,
      },
      licenseClassGroupIds: {
        //required,
      },
    };
  }

  public get licenseClassGroupsOptions(): Array<IBasicNamedDTO> {
    return this.licenseClassGroups;
  }
}
